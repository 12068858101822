*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

a {
    text-decoration: none;
    color:inherit;
}

ol, ul {
    list-style: none;
}

// Form fields
fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}

input, textarea {
    background: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    border: none;
    outline: none;
    font-family: "Open Sans", Arial, sans-serif;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button {
    background: none;
    border: 0;
    color: inherit;
    /* cursor: default; */
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    -webkit-appearance: button; /* for input */
    -webkit-user-select: none; /* for button */
       -moz-user-select: none;
        -ms-user-select: none;
}
input::-moz-focus-inner,
button::-moz-focus-inner {
    border: 0;
    padding: 0;
}


//placeholders
::-webkit-input-placeholder {
    color: $wh;
 }

 :-moz-placeholder { /* Firefox 18- */
    color: $wh;
 }

 ::-moz-placeholder {  /* Firefox 19+ */
    color: $wh;
 }

 :-ms-input-placeholder {
    color: $wh;
 }


textarea::-webkit-input-placeholder {
    color: $wh;
}

textarea:-moz-placeholder { /* Firefox 18- */
color: $wh;  
}

textarea::-moz-placeholder {  /* Firefox 19+ */
color: $wh;  
}

textarea:-ms-input-placeholder {
color: $wh;  
}