.offerSwip {
	text-align: center;

	.offerSwip__tabs {
		display: flex;
		justify-content: center;
		flex-direction: row;
		flex-wrap: wrap;
		// margin-bottom: 2rem;
		padding: 0;
		list-style: none;


		@media only screen and (min-width: $bp-slarge) {
			flex-direction: row;
		}

		.offerSwip__tab {
			padding: 1rem 2rem;
			border: 1px solid #bbbbbb;
			cursor: pointer;
			background-color: #f3f3f3;
			transition: background-color 0.3s ease;
			color: #444444;

			h3 {
				font-weight: 600;
				letter-spacing: 0.02em;
				opacity: 0.6;
			}

			&:hover {
				background-color: #ddd;

				h3 {
					opacity: 1;
				}
			}

			&.is-active {
				background-color: #fff;
				border-color: #bbb;
				font-weight: bold;
				border-bottom: unset;

				h3 {
					opacity: 1;
				}
			}

			+.offerSwip__tab {
				border-left: unset;
			}
		}
	}

	.offerSwip__content {
		position: relative;

		.offerSwip__tab-content {
			display: none;

			&.is-active {
				display: block;
			}

			.offerSwip__slide {
				color: #444444;
				display: flex;
				column-gap: 1rem;
				flex-wrap: wrap;
				background: #e6e6e6;
				padding: 2rem;
				height: inherit;
				align-items: flex-start;

				h4 {
					width: 100%;
					text-transform: uppercase;
					margin-bottom: 1.5rem;
				}

				p {
					text-align: left;
				}

				&-txt {
					display: flex;
					flex-direction: column;
					flex: 1 1 100%;
					gap: 1.5rem;

					@media only screen and (min-width: $bp-medium) {
						flex: 1 1 50%;
					}
				}

				&-img {
					width: auto;

					img {
						width: 100%;
						padding: 2px;
						border: 1px solid #dedede;
						box-sizing: border-box;
					}
				}
			}
		}

		.swiper-container {
			width: 100%;
			height: 100%;
			margin-top: 2rem;

			.swiper-slide {
				display: flex;
				justify-content: center;
				align-items: flex-start;
				text-align: center;
			}

			.swiper-pagination {
				bottom: 10px !important;
			}
		}
	}

	&__inner-wr {
		padding: 0;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		@media only screen and (min-width: $bp-mlarge2) {
			padding: 3rem;
		}

		&--2col {
			* {
				display: inline-block;
				width: 100%;

				@media only screen and (min-width: $bp-large) {
					width: 47%;
				}
			}

			strong {
				width: auto;
			}
		}
	}


	&-pagination {
		display: flex;
		justify-content: center;
		gap: 0.8rem;
		margin-top: -1rem;

		.swiper-pagination-bullet {
			width: 10px;
			height: 10px;
		}

		.swiper-pagination-bullet-active {
			background: #7294b0;
		}
	}
}
