.about {
	background-color: $section3;
	color: $main-text2;

	&__wrapper {
		width: 100%;
		padding: 4rem 2rem;
		color: white;
		background: url("../images/about_bg_mobile.jpg");
		background-repeat: no-repeat;
		background-position: right bottom;
		background-color: #999997;
		background-size: 140%;
		filter: saturate(1.4);

		@media only screen and (min-width: $bp-smallest) {
			background-size: auto;
		}

		@media only screen and (min-width: $bp-medium) {
			background: url("../images/about_bg.jpg");
			background-size: cover;
			background-repeat: no-repeat;
			background-position: right -185px center;
			background-color: #a4a5a0;

			// background-image:
			// linear-gradient(90deg, rgba(102,102,102,0.6) 0%, rgba(99,99,99,0.4) 50%, rgba(42,42,42,0) 100%), url("../images/about_bg.jpg");
		}

		@media only screen and (min-width: $bp-mlarge) {
			background-position: right -230px center;
		}

		@media only screen and (min-width: $bp-mlarge2) {
			background-position: right -160px center;
		}

		@media only screen and (min-width: $bp-xlarge) {
			background-position: right -125px center;
			padding: 8rem 10rem !important;
    		background-position: center;
			min-height: 525px;
		}
	}

	&__desc {
		width: 100%;
		margin-bottom: 250px;

		@media only screen and (min-width: $bp-smallest) {
			margin-bottom: 380px;
		}

		@media only screen and (min-width: $bp-medium) {
			margin-bottom: 0px;
			width: 70%;
			background-color: #858585b8;
			padding: 20px;
			margin: -20px;
		}
		
		@media only screen and (min-width: $bp-slarge) {
			width: 55%;
			background-color: #858585b8;
		}

		@media only screen and (min-width: $bp-mlarge2) {
			background-color: unset;
		}

		@media only screen and (min-width:$bp-xlarge) {
			width: 50%;
			font-size: 1rem;
		}
	}

	&__personnel {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

	}

	&__person {
		margin-bottom: 2rem;
		flex-basis: 100%;
		justify-content: space-between;
		margin-bottom: 3rem;
		height: fit-content;
		@media only screen and (min-width: $bp-medium) {
			flex-basis: 45%;

			&:first-of-type {
				flex-basis: 100%;
				margin-bottom: 4rem;
				@media only screen and (min-width: $bp-xlarge) {
					br {
						display: none;

					}
				}

			}
		}
		// @media only screen and (min-width: $bp-xlarge) {
		// 	flex-basis: 16%;

		// 	&:first-of-type {
		// 		flex-basis: 57%;
		// 		margin-bottom: 0;
		// 	}
		// }


	}

	&__person-name {
		font-size: 1.1rem;
		font-weight: $fw-medium;
		margin-bottom: 0.5rem;
		text-transform: uppercase;
		letter-spacing: 1.5px;
	}
	
	&__person-desc {
		line-height: 1.6;
	}
}

.my-gallery {
	margin-top: 1rem;
    width: 100%;
    height: 100%;
	@media only screen and (min-width: $bp-smallest) {
		margin: 1rem auto 0;
		max-width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	@media only screen and (min-width: $bp-medium) {
		max-width: 80%;
	}
	@media only screen and (min-width: $bp-large) {
		// max-width: 1100px;
		margin: 1rem auto;
		max-width: 100%;

	}



	& figure {
		display: flex;
		width: 50%;
		height: auto;
		float: left;
		// padding: 10px;

		@media only screen and (min-width: $bp-smallest) {
			width: 50%;
		}
		@media only screen and (min-width: $bp-small) {
			width: 25%;
		}

		@media only screen and (min-width: $bp-large) {
			width: 21%;
		}
		@media only screen and (min-width: $bp-mlarge2) {
			width: 17%;
		}


		&>a {
			width: 100%;
			margin: .5rem;
		}

		&>a>img {
			max-width: 100%;
			padding: 2px;
			border: 1px solid #dedede;
			opacity: .8;

			&:hover{
				opacity: 1;
			}
		}
	}
}

.pswp__bg {
	background: #000000ab !important;
}
