.gallery {
	margin-bottom: -8px;

	ul.swiper-wrapper {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	/* Swiper container */
	.swiper-container {
		max-width: 100%;
	}

	/* swiper responive image */
	.swiper-container img {
		width: 100%;
		height: auto;
	}

	.swiper-slide {
		/* Remove this if you want 1 slide perview - than change slidesPerView js-option to 1 -or- 2+ instead of 'auto' */
		width: 80%;
	}

	/* Swiper custom pagination */
	.swiper-pagination-bullet {
		width: 8px;
		height: 8px;
		text-align: center;
		line-height: 34px;
		font-size: 14px;
		color: #000;
		opacity: .7;
		background: $main-text2;
	}

	/* Swiper custom pagination active state */
	.swiper-pagination-bullet-active {
		color: #fff;
		background: $black2;
	}

	.swiper-button-prev {
		height: 100%;
		top: 0;
		left: 2%;
		padding: 8px;
		opacity: 0.2;
		-webkit-transition: opacity 0.6s;
		/* For Safari 3.1 to 6.0 */
		transition: opacity 0.6s;
	}

	.swiper-button-next {
		height: 100%;
		top: 0;
		right: 2%;
		padding: 8px;
		opacity: 0.2;
		-webkit-transition: opacity 0.6s;
		/* For Safari 3.1 to 6.0 */
    transition: opacity 0.6s;
	}

	.swiper-button-prev:hover,
	.swiper-button-next:hover {
		opacity: 1;
	}
}
