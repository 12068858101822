.visit {
	background-color: $black2;
	position: relative;
	background-image: url('../images/section-bg-4.jpg');
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
    background-position-x: center;
    color: $list-link;

	@media only screen and (min-width: $bp-large) {
		max-width: 100%;
		margin: 0 auto;

	}

	& .wrapper {
		@media only screen and (min-width: $bp-large) {
			padding: 9rem 10rem;
		}
	}
    
	&__text {
        width: 100%;
        margin: 2rem 0;
		font-size: 16px;
        
        @media only screen and (min-width: $bp-large) {
           font-size: 1.2rem;
           font-weight: 200;
        }
	}

	&__text-header {
		font-size: 18px;
		font-weight: 400;
		text-transform:uppercase;
        margin-bottom: 20px;

        @media only screen and (min-width: $bp-large) {
            font-size: 1.5rem;
         }
	}

	&__img {
		position: absolute;
		background: url('../images/m_rez.png');
		background-repeat: no-repeat;
		background-size: contain;
		width: 30%;
		height: 100%;
		top: -40px;
		right: 0;
	}
}
