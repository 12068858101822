.btn {
	text-decoration: none;
	color: $wh;
	font-size: 1rem;
	font-weight: 400;
	background-color: $contact-link;
	text-transform: uppercase;
	padding: 1.2rem 4rem;
	display: inline-block;
	text-align: center;
	transition: all .5s;
	position: relative;
	margin: 15px;
	margin-top: 3rem;

	@media only screen and (min-width: $bp-medium) {
		padding: 1.5rem 4rem;
	}

	@media only screen and (min-width: $bp-large) {
		font-size: 14px;
		padding: 1rem 3rem;
		margin-top: 2rem;
	}

	//   &:before {
	//      position: absolute;
	//      z-index: -1;
	//      content: '';
	//      top: 100%;
	//      left: 5%;
	//      height: 10px;
	//      width: 90%;
	//      opacity: 0;
	//      background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
	//      background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
	//    transition-duration: 0.3s;
	//      transition-property: transform, opacity;
	//  }

	//   &:hover:before {
	//      transform: translateY(5px);
	//      opacity: 1;
	//   }
	&--bold {
		font-weight: 900;
	}

	&--ghost {
		background-color: transparent;
		outline: none;
		border: 1px solid rgba($color: #fff, $alpha: 0.5);
		transition: background-color 0.2s ease-out,
			border-color 0.2s ease-out;

		&:hover {
			background-color: #fff;
			background-color: rgba(255, 255, 255, 0.3);
			border-color: #fff;
			border-color: rgba(255, 255, 255, 0.4);
			transition: background-color 0.3s ease-in,
				border-color 0.3s ease-in;
		}
	}
}

.scroll {
	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	-webkit-overflow-scrolling: auto;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	margin-bottom: 4rem;
}

::-webkit-scrollbar {
	-webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
	width: 12px;
}

::-webkit-scrollbar:horizontal {
	height: 12px;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, .1);
	border-radius: 10px;
	border: 2px solid #ffffff;
}

::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: #ffffff;
}

.red-link {
	color: $icons-bg2;
	border-bottom: 1px solid transparent;
	transition: all .9s;

	&:hover {
		border-color: $icons-bg2;
	}
}

.break {
	&:before {
		content: "-";
	}

	&:after {
		display: block;
		content: "";
	}

	@media only screen and (min-width: $bp-large) {

		&:before,
		&:after {
			content: "";
			display: inline;
		}
	}
}

.d-disabled {
	@media only screen and (min-width: $bp-large) {
		display: none;
	}
}

.disabled {
	display: none;
}

.bullet-list {
	margin-left: 15px;

	li::before {
		content: "•";
		color: red;
		display: inline-block;
		width: 15px;
		margin-left: -15px;
		font-weight: $fw-bold;
	}
}

ol {
	list-style-type: decimal;
	text-align: left;

	li {
		margin-bottom: 20px;
		padding-left: 10px;
	}
}

ol li>ol {
	list-style-type: lower-alpha;
	margin-left: 30px;

	li:first-child {
		margin-top: 20px;
	}
}

.ul-content {
	li {
		text-align: left;
	}

	ul {
		text-align: left;
		margin-top: 20px;

		li {
			margin-bottom: 20px;
			padding-left: 10px;
		}
	}
}


.cl-2 {
	@media only screen and (min-width: $bp-large) {
		column-count: 2;
		column-gap: 50px;
	}
}

hr {
	border-top: 1px solid $list-link;
	border-bottom: none;
	margin-top: 40px;
	margin-bottom: 20px;

}

.mobile-view {
	position: fixed;


	height: 100%;
	width: 100%;
	overflow: hidden;

}

h2 {
	font-size: 19px;
	font-weight: 400;
	text-transform: uppercase;
	text-align: left;
	letter-spacing: 0.1em;
	line-height: 1.4;
	margin-bottom: 4rem;
}

h3 {
	font-size: 17px;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	line-height: 1.4;
}

:focus {
	outline: 0;
}
