.offer {
	text-align: center;

	@media screen and (min-width: $bp-large) {
		padding-bottom: 60px;
	}

	&__header {
		color: $header;
	}

	&__wrapper {
		display: flex;
		flex-flow: row wrap;
	}

	&__item {
		margin: 15px;
		width: calc((100% / 3) - 30px);
		transition: all 0.2s ease-in-out;

		//media queries for stacking cards
		@media screen and (max-width: 991px) {
			width: calc((100% / 2) - 30px);
		}

		@media screen and (max-width: 767px) {
			margin: 15px 0;
			width: 100%;
		}

		&:hover {
			.offer__inner {
				background-color: rgba(243, 243, 243, .7);
				// border-color: #bbbbbb;
				transition: background-color 0.3s ease-in,
					border-color 0.3s ease-in;
			}
		}
	}


	&__inner {
		width: 100%;
		padding: 30px;
		position: relative;
		cursor: pointer;
		border: 1px solid #bbbbbb;
		color: lightgray;
		letter-spacing: 2px;
		text-transform: uppercase;
		text-align: center;
		color: $main-text3;
		transition: all 0.2s ease-in-out;

		border: 1px solid rgba($color: #bbbbbb, $alpha: 0.9);
		transition: background-color 0.2s ease-out,
			border-color 0.2s ease-out;

		&:after {
			transition: all 0.3s ease-in-out;
		}

		& h3 {
			font-weight: $fw-semibold;
			letter-spacing: 0.02em;
		}
	}


	&__expander {
		transition: all 0.2s ease-in-out;
		background-color: #f3f3f3;
		width: 100%;
		position: relative;
		// display: flex;
		// justify-content: center;
		// align-items: center;
		text-transform: uppercase;
		color: lightgray;
		border: 1px solid #e4e4e4;
		top: -1px;

		&--scroll {
			overflow-y: scroll !important;
			max-height: 550px;
		}
	}

	&__text {
		color: $main-text3;
		text-transform: none;
		text-align: left;
		width: 100%;

		&--center {
			text-align: center;
		}
	}

	&__img {
		width: 100%;
		// height: 100%;
		margin-top: 3rem;

		& img {
			width: 100%;
			padding: 2px;
			border: 1px solid #dedede;

		}

		@media only screen and (min-width: $bp-large) {
			margin-top: 0;
		}
	}

	&__inner-wr {
		padding: 2rem 1rem;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		@media only screen and (min-width: $bp-mlarge2) {
			margin: 2rem 0;
			padding: 3rem;
		}

		&--2col {
			* {
				display: inline-block;
				width: 100%;

				@media only screen and (min-width: $bp-large) {
					width: 47%;
				}
			}

			strong {
				width: auto;
			}
		}
	}

	&__inner-item {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 3rem;
	}

	&__hr {
		display: none;

		@media only screen and (min-width: $bp-medium) {
			display: block;
			height: 1px;
			width: 80%;
			background-color: #d3d3d3;
			margin: 2.5rem auto;
		}
	}

	&__desc {
		width: 100%;
		height: auto;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		margin-bottom: 4rem;
		justify-content: center;

		@media only screen and (min-width: $bp-medium) {
			width: 45%;
			margin-bottom: 0;
		}

		& .offer__img {
			align-self: flex-end;
			margin-top: 2rem;
			max-width: 450px;
			max-height: 450px;
		}
	}



	.offer__slide {
		color: #444444;
		display: flex;
		column-gap: 1rem;
		flex-wrap: wrap;
		padding: 2rem 1rem;
		height: inherit;
		align-items: flex-start;

		@media only screen and (min-width: $bp-medium) {
			padding: 2rem;
		}

		h4 {
			width: 100%;
			text-transform: uppercase;
			font-weight: normal;
			max-width: 80%;
			margin: auto;
			padding-bottom: 2rem;
			line-height: 1.2;
			min-height: 76px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 16px;
			@media only screen and (min-width: $bp-medium) {
				font-size: 1.2rem;
			}
		}

		p {
			text-align: left;
			text-transform: initial;
		}

		&-txt {
			display: flex;
			flex-direction: column;
			width: 100%;
			margin-bottom: 1rem;

			@media only screen and (min-width: $bp-mlarge) {
				flex: 1 1 50%;
			}
		}

		&-img {
			width: auto;
			flex: 2 2 0;
			max-width: max-content;
			margin: auto;

			@media only screen and (min-width: $bp-mlarge) {
				margin: unset;
			}

			img {
				width: 100%;
				padding: 2px;
				border: 1px solid #dedede;
				box-sizing: border-box;
			}
		}
	}

	.swiper-wrapper {
		max-height: unset !important;
	}

	.swiper-button-prev,
	.swiper-button-next {
		top: 60px;
		width: 34px;
		height: 34px;
		display: inline-block;
		background: none;

		&::after {
			content: '';
			position: absolute;
			border-right: 4px solid #7294b0;
			border-bottom: 4px solid #7294b0;
			width: 24px;
			height: 24px;
			right: 5px;
			top: 5px;
		}

	}

	.swiper-button-next {
		&::after {
			transform: rotate(-45deg);
		}

		@media only screen and (min-width: $bp-medium) {
			right: 40px;
		}
	}

	.swiper-button-prev {
		&::after {
			transform: rotate(135deg);
		}

		@media only screen and (min-width: $bp-medium) {
			left: 40px;
		}
	}

	&-pagination {
		display: flex;
		justify-content: center;
		gap: 0.8rem;
		margin-top: -1rem;
		z-index: 10;
		width: 100%;

		.swiper-pagination-bullet {
			width: 10px;
			height: 10px;
			position: relative; 

			&::before {
				content: ''; 
				position: absolute;
				top: -6px; 
				bottom: -6px; 
				left: -6px;
				right: -6px; 
				background-color: transparent;
				cursor: pointer; 
			  }
		}

		.swiper-pagination-bullet-active {
			background: #7294b0;
		}
	}
}

.swiper-container {
	width: 100%;
	height: 100%;
	margin-top: 2rem;

	.swiper-slide {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		text-align: center;
	}

	.swiper-pagination {
		bottom: 10px !important;
	}
}

.is-collapsed {

	.offer__inner {
		&:after {
			content: "";
			opacity: 0;
		}
	}

	.offer__expander {
		max-height: 0;
		min-height: 0;
		overflow: hidden;
		margin-top: 0;
		opacity: 0;
	}
}

.is-expanded {

	.offer__inner {
		background-color: #fff;
		height: 100px;
		border: 1px solid #e4e4e4;
		border-bottom: none;
		z-index: 100;

		&:after {
			content: "";
			opacity: 1;
			display: block;
			height: 0;
			width: 0;
			position: absolute;
			bottom: -15px;
			left: calc(50% - 15px);
			border-left: 15px solid transparent;
			border-right: 15px solid transparent;
			border-top: 15px solid #fff;
		}
	}

	&.offer__item {
		&:hover {
			.offer__inner {
				background-color: #fff;
				transform: none;
			}
		}
	}

	.offer__expander {
		// max-height: 1000px;
		min-height: 200px;
		overflow: visible;
		opacity: 1;
	}
}

.is-inactive {
	.offer__inner {
		opacity: 0.5;
	}

	&.offer__item {
		&:hover {
			.offer__inner {
				background-color: #fff;
			}
		}
	}
}


@media screen and (min-width: 992px) {

	.offer__item:nth-of-type(3n+2) .offer__expander {
		margin-left: calc(-100% - 30px);
	}

	.offer__item:nth-of-type(3n+3) .offer__expander {
		margin-left: calc(-200% - 60px);
	}

	.offer__item:nth-of-type(3n+4) {
		clear: left;
	}

	.offer__expander {
		width: calc(300% + 60px);
	}

}

//when 2 cards in a row
@media screen and (min-width: 768px) and (max-width: 991px) {

	.offer__item:nth-of-type(2n+2) .offer__expander {
		margin-left: calc(-100% - 30px);
	}

	.offer__item:nth-of-type(2n+3) {
		clear: left;
	}

	.offer__expander {
		width: calc(200% + 30px);
	}
}
