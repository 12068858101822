$bp-largest: 75em;     // 1200px

// breakpoints
$bp-xlarge: 1600px;
$bp-mlarge2: 1440px;    
$bp-mlarge:1200px;    
$bp-slarge:1024px;    
$bp-large: 990px;  
$bp-medium: 750px;   
$bp-small: 600px;     
$bp-smallest: 400px; 

// font weight
$fw-extralight: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;
$fw-extrabold: 900;


$wh: #f0f0f0;
$black: #000;
$black2: #2c2c2c;
$burger-menu: #fff;
$contact-link: #afafaf;
$header: #323232;
$section-menu: #b9b9b9;
$section-menu-active: #e84d4e;
$section-menu-border: #ff9900;
$section-menu-border2: #f9f9f9;
$icons-bg: #ffb31c;
$icons-bg2: #e84d4e;
$logo-text: #404041;
$burger-menu-bg: #413d3c;
$link-color: #5e606b;

$section-subheader: #404041;
$main-text: #a3a3a3;
$main-text2: #323232;
$main-text3: #444444;
$main-text-light: #b1b1b1;
$section: #808080;
$section2: #9c9c9c;
$section3: #ececec;
$list-link : #c0c0c0;
$slide-bg: #04d26f;
$slide-h: #41485a;
$slide-text: #3c3c3c;
$swiper-bullet: #a8a8a8;
$swiper-bullet-active: #8a8a8a;