.footer {
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1.3px;
	color: $main-text-light;
	background-color: #141414;

	& .wrapper {
		@media only screen and (min-width: $bp-large) {
			padding: 9rem 5rem;
		}

		@media only screen and (min-width: $bp-xlarge) {
			padding: 9rem 15rem;
		}
	}

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		align-items: center;


	}

	&__logo {
		max-width: 100%;
		margin-bottom: 4.5rem;
		flex-basis: 100%;

		@media only screen and (min-width: $bp-mlarge) {
			flex-basis: 1%;
			margin-bottom: 0;
		}

		@media only screen and (min-width: $bp-mlarge2) {
			flex-basis: 20%;
		}

		& img {
			width: 70px
		}
	}

	&__text {
		font-size: 1.4rem;
		margin-bottom: 3.5rem;

		@media only screen and (min-width: $bp-large) {
			font-size: 18px;
		}
	}

	&__address {
		font-style: normal;
		font-size: 1rem;
		line-height: 2.3;
		margin-bottom: 1rem;
		width: 100%;
		word-break: break-word;

		@media only screen and (min-width: $bp-mlarge) {
			font-size: 14px;
			width: 33%;
			margin-bottom: 0;
		}

		& a {
			&:hover {
				color: $link-color;
			}
		}
	}

	&__mail {
		color: $main-text-light;
		letter-spacing: 0.2px;
		margin-top: 1rem;
		display: block;

		@media only screen and (min-width: $bp-medium) {
			letter-spacing: 1.5px;
		}
	}

	&__map {
		width: 100%;
		height: 300px;
		background-image: url('../images/map_vertebra_2.jpg');
		background-size: auto;
		background-position: center center;

		@media only screen and (min-width: $bp-large) {
			background-size: cover;
		}
	}

	&__rights {
		padding: 2rem;

		@media only screen and (min-width: $bp-large) {
			padding: 2rem 5rem;

		}

		p {
			font-size: .8rem;
			width: fit-content;
			display: inline-block;
			margin: 0 1rem;
		}
	}
}
