.hero {
	position: relative;
	background-color: rgb(20, 20, 20);


	&.active {

		.hero__header,
		.hero__text,
		.hero__link {
			opacity: 1;
			transform: translateY(0);
		}

		.hero__logo-link {
			opacity: 1;
		}

		.hero__nav-list-item {
			opacity: 1;
		}
	}

	&__header-header {
		max-width: 1700px;
		margin: 0 auto;
		padding: 3rem 1.5rem;

		@media only screen and (min-width: $bp-medium) {
			padding: 45px 40px;
		}
		@media only screen and (min-width: $bp-slarge) {
			padding: 45px 50px;
		}
	}

	&__nav-list {
		display: none;

		@media only screen and (min-width: $bp-slarge) {
			display: flex;
			justify-content: center;
			list-style-type: none;
			position: relative;

			&--menu {
				.hero__nav-list-item:nth-of-type(1) {
					transition: all .3s;
				}

				.hero__nav-list-item:nth-of-type(2) {
					transition: all .3s .1s;
				}

				.hero__nav-list-item:nth-of-type(3) {
					transition: all .3s .2s;
				}

				.hero__nav-list-item:nth-of-type(4) {
					transition: all .3s .3s;
				}

				.hero__nav-list-item:nth-of-type(5) {
					transition: all .3s .4s;
				}

				.hero__nav-list-item:nth-of-type(6) {
					transition: all .3s .5s;
				}

				.hero__nav-list-item:nth-of-type(7) {
					transition: all .3s .6s;
				}
			}

			&--social {
				.hero__nav-list-item:nth-of-type(1) {
					transition: all .3s .5s;
				}

				.hero__nav-list-item:nth-of-type(2) {
					transition: all .3s .6s;
				}
			}

			&-item {
				font-size: 10px;
				text-transform: uppercase;

				opacity: 0;

				@media only screen and (min-width: $bp-mlarge) {
					font-size: 12px;
				}


				&:not(:last-of-type) {

					margin-right: 15px;

					@media only screen and (min-width: $bp-mlarge) {
						margin-right: 35px;
					}
				}

				a {
					position: relative;
					padding-bottom: 13px;
				}

				a,
				a:after {
					transition: all .5s
				}

				a:after {
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					margin: auto;
					width: 0%;
					content: '.';
					color: transparent;
					background: $icons-bg2;
					height: 1px;
				}

				@media only screen and (min-width: $bp-slarge) {
					a:hover {
						color: #d7d7d7;
					}

					a:hover:after {
						width: 100%;
					}
				}
			}

			&-social-item {
				height: 19px;

				img {
					height: 100%;
				}
			}
		}

		&-mobile {
			// display: none;
			list-style: none;
			height: 100vh;
			width: 100%;
			padding: 0;
			position: fixed;
			left: 0;
			right: 0;
			transform: translateY(-1500px);
			transition: transform .4s;
			margin: 0;
			padding-top: 7vh;
			padding-bottom: 7vh;
			top: 0;
			z-index: 100;
			text-align: center;
			font-weight: $fw-extralight;
			font-size: 1.8rem;


			&--visible {
				transform: translateY(0px);
			}

			&-info {
				opacity: 0;
				z-index: 99;
				flex-basis: 50%;
				text-align: left;

				p+p {
					margin: 3rem 0;
				}

				a {
					font-size: 3rem;
					margin-top: 3.5rem;

					&:hover {
						color: $contact-link;
					}
				}

			}


			&-item {

				&--social {
					// bottom: 80px;
					display: flex;
					justify-content: center;
					width: 100%;

					a {
						margin: 0 25px;
					}
				}

				.hero__logo {
					max-width: 160px;
					margin-bottom: 3rem;
				}

				&:hover a {
					color: $contact-link;
				}
			}

			&-item a {
				color: $wh;
				text-decoration: none;
				display: block;
				padding: 4vh 0;
				line-height: 1;
				text-transform: uppercase;
			}

			&-item--logo {
				text-align: left;
				z-index: 100;

				a {
					display: inline-block;
					padding-top: 0;
				}
			}

			&--visible .hero__nav-list-inner:after,
			&--visible .hero__nav-list-inner:before {
				-webkit-transform: scaleY(1) translateZ(0);
				transform: scaleY(1) translateZ(0)
			}

			&--visible .hero__nav-list-inner ul {
				opacity: 1;
				transition: opacity 1.2s cubic-bezier(.165, .84, .44, 1) .8s;
			}

			&--visible .hero__nav-list-mobile-info {
				display: none;

				@media only screen and (min-width: $bp-slarge) {
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding-left: 5%;
					opacity: 1;
					transition: opacity 1.8s cubic-bezier(.165, .84, .44, 1) 1s;
				}
			}


		}

		&-inner {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			padding: 8vh;
			flex-direction: column;

			&:after,
			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 50%;
				height: 100%;
				background: rgb(39, 39, 39);
				-webkit-transform-origin: top;
				transform-origin: top;
				-webkit-transform: scaleY(0) translateZ(0);
				transform: scaleY(0) translateZ(0);
				transition: -webkit-transform 1.2s cubic-bezier(.77, 0, .175, 1) .2s;
				transition: transform 1.2s cubic-bezier(.77, 0, .175, 1) .2s;
				transition: transform 1.2s cubic-bezier(.77, 0, .175, 1) .2s, -webkit-transform 1.2s cubic-bezier(.77, 0, .175, 1) .2s;
				// -webkit-transform: scaleY(1) translateZ(0);
				// transform: scaleY(1) translateZ(0)
			}

			&:after {
				left: 50%;
				transition-delay: 0s
			}

			@media only screen and (min-width: $bp-slarge) {
				flex-direction: row;
			}


			ul {
				flex-basis: 50%;
				justify-content: center;

				z-index: 99;
				opacity: 0;
				-webkit-transform: translateZ(0);
				transform: translateZ(0);
				transition: opacity .4s cubic-bezier(.165, .84, .44, 1);

				@media only screen and (min-width: $bp-slarge) {
					text-align: left;
					display: flex;
					flex-direction: column;
					justify-content: space-evenly;
				}


			}

		}
	}

	&__logo-link {
		opacity: 0;
		transition: all 1s;
	}

	&__logo {
		max-width: 180px;
		width: 80%;

		@media only screen and (min-width: $bp-small) {
			max-width: 230px;
			width: auto;
		}
	}

	&__nav-burger {
		position: absolute;
		top: 11%;
		right: 6%;
		z-index: 101;
		width: 27px;
		transform: translateY(-50%);
		cursor: pointer;
		filter: contrast(0.5);

		@media only screen and (min-width: $bp-slarge) {
			position: fixed;
			top: 8%;
			right: 3%;
		}

		&:hover {
			opacity: .8;
		}

		span {
			display: block;
			width: 100%;
			height: 2px;
			background: #fff;
			transition: all .3s;
			position: relative;
		}

		&.on-scroll {
			span {
				background: black;
			}
		}

		span+span {
			margin-top: 6px;
		}

		&--active span {
			color: white !important;
		}

		&--active span:nth-child(1) {
			animation: ease .7s top forwards;
			margin-top: 2px;
			background-color: white;
		}

		&--not-active span:nth-child(1) {
			animation: ease .7s top-2 forwards;
		}

		&--active span:nth-child(2) {
			animation: ease .7s scaled forwards;
			background-color: white;
		}

		&--not-active span:nth-child(2) {
			animation: ease .7s scaled-2 forwards;
		}

		&--active span:nth-child(3) {
			animation: ease .7s bottom forwards;
			margin-top: 34px;
			background-color: white;
		}

		&--not-active span:nth-child(3) {
			animation: ease .7s bottom-2 forwards;
		}

		@keyframes top {
			0% {
				top: 0;
				transform: rotate(0);
			}

			50% {
				top: 22px;
				transform: rotate(0);
			}

			100% {
				top: 22px;
				transform: rotate(45deg);
			}
		}

		@keyframes top-2 {
			0% {
				top: 22px;
				transform: rotate(45deg);
			}

			50% {
				top: 22px;
				transform: rotate(0deg);
			}

			100% {
				top: 0;
				transform: rotate(0deg);
			}
		}

		@keyframes bottom {
			0% {
				bottom: 0;
				transform: rotate(0);
			}

			50% {
				bottom: 22px;
				transform: rotate(0);
			}

			100% {
				bottom: 22px;
				transform: rotate(135deg);
			}
		}

		@keyframes bottom-2 {
			0% {
				bottom: 22px;
				transform: rotate(135deg);
			}

			50% {
				bottom: 22px;
				transform: rotate(0);
			}

			100% {
				bottom: 0;
				transform: rotate(0);
			}
		}

		@keyframes scaled {
			50% {
				transform: scale(0);
			}

			100% {
				transform: scale(0);
			}
		}

		@keyframes scaled-2 {
			0% {
				transform: scale(0);
			}

			50% {
				transform: scale(0);
			}

			100% {
				transform: scale(1);
			}
		}
	}

	&__nav {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;

	}

	&__article {
		padding: 0 50px 50px;
		max-width: 630px;
		margin: 0 auto;
		min-height: 400px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-end;

		@media only screen and (min-width: $bp-large) {
			max-width: 1100px;
		}
	}

	&__img {
		display: flex;
		width: 555px;
		margin: 0 auto;
		justify-content: center;
		align-items: flex-end;
		margin-top: 50px;

		@media only screen and (min-width: $bp-small) {
			margin-top: 0px;
			width: 625px;

		}

		img {
			max-width: 100%;
			max-height: 100%;
			filter: contrast(0.5);
			// opacity: .6;

			&:hover {
				opacity: .9;
			}
		}
	}


	&__header {
		font-family: "Open Sans", Arial, sans-serif;
		font-size: 1rem;
		font-weight: 400;
		text-transform: uppercase;
		letter-spacing: 5px;
		hyphens: auto;
		line-height: 1.8;
		margin-bottom: 20px;
		margin-top: 40px;
		flex: 1 1 100%;
		text-align: center;
		opacity: 0;
		transform: translateY(100%);
		transition: all .5s .3s;

		& span {
			display: none;
		}

		@media only screen and (min-width: $bp-small) {
			font-size: 1.2rem;
			margin-bottom: 60px;
			margin-top: 40px;

			& br {
				display: none;
			}

			& span {
				display: inline-block;
			}

		}
	}

	&__text {
		font-family: "Open Sans", Arial, sans-serif;
		font-weight: 200;
		font-size: 1.2rem;
		margin-top: 5px;
		flex: 1 1 100%;
		text-align: center;
		opacity: 0;
		transform: translateY(100%);
		transition: all .5s .4s;

		@media only screen and (min-width: $bp-smallest) {
			font-size: 1.3rem;
		}

		@media only screen and (min-width: $bp-large) {
			font-size: 1.3rem;
			margin-top: 30px;
			margin-bottom: 50px;
		}

		& span {
			font-weight: 700;
		}
	}

	&__link {

		opacity: 0;
		transform: translateY(100%);
		transition: all .5s .5s;

	}

	&__down-arrow {
		display: block;
		width: 40px;
		height: 40px;
		// background: transparent url('../images/arrow.png') no-repeat 50% 50% / 100%;
		margin: 50px auto 0;
		cursor: pointer;
		transition: all .5s;

		&:hover {
			transform: translateY(10px);
		}
	}
}
