@import 'shared/variables';
@import 'shared/reset';
@import 'shared/url';
@import 'shared/common';
@import 'components/img';
@import 'components/about';
@import 'components/hero';
@import 'components/offer';
@import 'components/offer_swiper';
@import 'components/visit';
@import 'components/gallery';
@import 'components/footer';

html {
    font-size: 100%; 

    @media only screen and (min-width: $bp-mlarge) {
        font-size: 80%;
    }
    font-family: "Open Sans", arial, sans-serif;
}

body{
    box-sizing: border-box;
    color: $main-text;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    padding: 0;
    margin: 0;
    line-height: 1.9;
    position: relative;
}

.wrapper {
    padding: 4rem 1.5rem;
    overflow: hidden;

    @media only screen and (min-width: $bp-small) {
        padding: 4rem;
    }
    @media only screen and (min-width: $bp-mlarge) {
        padding: 5rem ;
    }
    @media only screen and (min-width: $bp-xlarge) {
        padding: 5rem 8rem;
    }
}

